import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';

interface Props {
  children?: React.ReactNode;
  preTitle: string;
  title: string;
}

const Idle = ({ children, preTitle, title }: Props) => (
  <Box aria-live="assertive">
    {/* Illustration or images */}
    <Box mb="space4" width="100%">
      {children}
    </Box>
    <Text mb="space2" color="textWeaker" mt="space6">
      {preTitle}
    </Text>
    <Text fontSize="175" mb="space2" lineHeight="snug">
      {title}
    </Text>
  </Box>
);

export default Idle;
