import * as React from 'react';
import { Link } from '@mentimeter/ragnar-ui/link';
import { useSplitTrack } from '../../../split.io';
import { FooterContainer } from './FooterContainer';
import { Terms } from './Terms';

const wwwUrl = process.env.NEXT_PUBLIC_WWW_URL;

const DEFAULT_UTM_URL_PARAMS =
  'utm_campaign=powered%20by%20mentimeter%20button&utm_medium=web-link&utm_source=govote';

interface WebFooterProps {
  utmUrlParams?: string | undefined;
  onMentimeterLinkClick?: (() => void) | undefined;
  context?: 'Home' | 'FinishScreen' | undefined;
}

export const DefaultFooter = ({
  utmUrlParams = DEFAULT_UTM_URL_PARAMS,
  onMentimeterLinkClick,
  context,
}: WebFooterProps) => {
  const trackSplit = useSplitTrack();

  const handleMentimeterLinkClick = () => {
    trackSplit('Clicked_through_to_mentimeter');
    onMentimeterLinkClick?.();
  };

  return (
    <>
      <FooterContainer.Text mb="space6">
        Create your own Menti at&nbsp;
        <Link
          href={`${wwwUrl}/auth/justvoted?${utmUrlParams}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleMentimeterLinkClick}
        >
          mentimeter.com
        </Link>
      </FooterContainer.Text>
      {context !== 'FinishScreen' && <Terms />}
    </>
  );
};
